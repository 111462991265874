<template>
  <div class="page">
    <div class="item">
      <conditional-search @onSearch='onSearch' v-model="check" :num="page.total" :new_batch="new_batch" />
    </div>

    <div style="min-height: 100px">
      <college-list v-loading="loading" v-for="(item, i) in CollegeData" :rankingData="rankingData" :userInfo="userInfo"
        :oldLine="oldLine" :item="item" :key="i" @refush=updateReport :state="state" :subject_id="subject_id">
      </college-list>
    </div>
    <div class="main">
      <template>
        <div style="text-align: center; margin-bottom: 20px">
          <el-pagination @current-change="handleCurrentChange" :page-size="page.pageSize" layout=" prev, pager, next"
            :total="page.total" :current-page.sync="page.currentPage">
          </el-pagination>
        </div>
      </template>
    </div>

  </div>
</template>

<script>
import ConditionalSearch from "./components/ConditionalSearchSpecialty";
import CollegeList from "./components/CollegeList";
import { mapState } from "vuex";
export default {
  props: ['updateStatus', 'rankingData', 'subject_id', 'new_batch'],
  computed: { ...mapState(["userInfo"]) },
  name: '',
  data() {
    return {
      CollegeData: [],
      //筛选条件
      check: {
        code_list: null,
        level: null
      },
      page: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
        currentPage: 1,
      },
      oldLine: [
        { year: 2024, score: 0 },
        { year: 2023, score: 0 },
        { year: 2022, score: 0 },
        { year: 2021, score: 0 }
      ],
      len: 48,
      state: 1,
    };
  },

  components: {
    ConditionalSearch,
    CollegeList
  },
  created() {
    this.$emit("Nav", 2);
    this.getList()
    this.getOldCityScore()
  },
  watch: {
    new_batch(e) {
      this.getList()
      this.getOldCityScore()
      console.log(e)
    }
  },
  methods: {

    //搜索
    onSearch(e) {
      this.check = e
      console.log(this.check)
      this.page.currentPage = 1
      this.page.pageNum = 1
      this.getList()
    },


    //推荐模式
    getList() {
      this.$fecth
        .post("volunteer_demand/specialtyRecommend", {
          pageNum: this.page.pageNum,
          pageSize: this.page.pageSize,

          level: this.check.level,
          code_list: this.check.code_list,
          ranking: this.rankingData.ranking * 5 + this.rankingData.ranking_1 * 3 + this.rankingData.ranking_2 * 2,
          score: this.userInfo.score,
          subject_id: this.subject_id,
          subject: this.userInfo.subject,
          new_batch: this.new_batch,
        })
        .then((res) => {
          console.log(res)
          this.loading = false;
          this.CollegeData = res.lists;
          this.page.total = res.count;
        });
    },

    // 根据批次往年录取分数线
    getOldCityScore() {
      this.$fecth
        .post("common/getOldLine", {
          subject: this.userInfo.subject,
          new_batch: this.new_batch,
        })
        .then((res) => {
          this.oldLine = res
        });
    },

    updateReport(record) {
      this.$fecth
        .post("volunteer/updateReport", {
          user_id: this.userInfo.id,
          subject: this.userInfo.subject,
          new_batch: this.new_batch,
          record: JSON.stringify(record)
        })
      this.state++
    },
    //翻页
    handleCurrentChange(val) {
      this.page.pageNum = val;
      this.getList();
    },

  },
};
</script>

<style scoped lang='less'>
.page {
  width: 1200px;
  background: white;
  padding: 20px 0px;
}
</style>
